import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '@/components/Layout';
import Public from '@/components/Public';
import Login from '@/features/auth/Login';
import Welcome from '@/features/auth/Welcome';
import RequireAuth from '@/features/auth/RequireAuth';
import IfMapper from '@/components/IfMapper';

import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes */}
        <Route index element={<Public />} />
        <Route path="mapper/login" element={<Login navigate_on_success="/mapper" />} />
        {/* Protected Routes */}
        <Route element={<RequireAuth />}>
          <Route path="welcome" element={<Welcome />} />
          <Route path="mapper" element={<IfMapper />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;

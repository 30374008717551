import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '@/features/auth/authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.HOST_BACKEND_PROTOCOL}${process.env.HOST_BACKEND_DOMAIN}`,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const { access_token } = getState().auth;
    if (access_token) {
      headers.set('authorization', `Bearer ${access_token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.originalStatus === 403) {
    console.log('Sending Refresh Token');
    // Send the refresh token to get new access token
    const refreshResult = await baseQuery(
      '/api/v1/auth/refresh',
      api,
      extraOptions,
    );
    console.log(refreshResult);
    if (refreshResult?.data) {
      const { email } = api.getState().auth;
      // store the new token
      api.dispatch(setCredentials({ ...refreshResult.data, email }));
      // Retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispath(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});

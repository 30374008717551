import { useSelector } from 'react-redux';
import { selectCurrentEmail, selectAccessToken, selectRefreshToken } from '@/features/auth/authSlice';

function IfMapper() {
  const email = useSelector(selectCurrentEmail);
  const access_token = useSelector(selectAccessToken);
  const refresh_token = useSelector(selectRefreshToken);

  const welcome = email ? `Welcome ${email}!` : 'Welcome';
  const accessTokenAbbr = `${access_token.slice(0, 9)}...`;
  const refreshTokenAbbr = `${refresh_token.slice(0, 9)}...`;

  const content = (
    <section className="welcome">
      MAPPER DE MAP MAP
      <h1>{welcome}</h1>
      <p>
        Access Token:
        {accessTokenAbbr}
      </p>
      <p>
        Refresh Token:
        {refreshTokenAbbr}
      </p>
    </section>
  );

  return content;
}

export default IfMapper;

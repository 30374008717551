import logo from '@/../../static/logo.png';

import { Divider, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

function Public() {
  const navigate = useNavigate();

  const content = (
    <section>
      <img src={logo} alt="logo" />
      <Divider orientation="left" style={{ color: 'white' }}>Portal - Quickstart</Divider>
      <Button.Group>
        <Button
          onClick={() => window.open('/api/docs', '_blank', 'noopener,noreferrer')}
          style={{
            color: 'white',
            width: '185px',
            marginRight: '5px',
          }}
          type="normal"
          size="default"
        >
          API Documentation
        </Button>
        <Button
          onClick={() => window.open(
            'https://klant.exsell.nl/',
            '_blank',
            'noopener,noreferrer',
          )}
          style={{ color: 'white' }}
          type="primary"
          size="large"
        >
          Open Portal
        </Button>
        <Button
          onClick={() => window.open('/api/swagger', '_blank', 'noopener,noreferrer')}
          style={{
            color: 'white',
            width: '185px',
            marginLeft: '5px',
          }}
          type="normal"
          size="default"
        >
          Swagger Documentation
        </Button>
      </Button.Group>
      <Divider orientation="left" />
      <Button.Group size="small">
        <Button
          onClick={() => window.open('/admin', '_blank', 'noopener,noreferrer')}
          style={{
            color: 'white',
            width: '185px',
            marginLeft: '2.5px',
          }}
          type="normal"
          size="small"
        >
          Admin Dashboard
        </Button>
        <Button
          onClick={() => navigate('/mapper/login')}
          style={{
            color: 'white',
            width: '185px',
            marginRight: '2.5px',
          }}
          type="normal"
          size="small"
        >
          Mapper Dashboard
        </Button>
      </Button.Group>
    </section>
  );
  return content;
}
export default Public;

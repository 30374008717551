import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Spin, Card, Input, Button, Space,
} from 'antd';
import { setCredentials } from './authSlice';
import { useLoginMutation } from './authApiSlice';

const Login = ({ navigate_on_success = '/welcome' }) => {
  const emailRef = useRef();
  const errRef = useRef();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  // Focus email field
  useEffect(() => {
    emailRef.current.focus();
  }, []);

  // Set error message to empty once email or password field changes
  useEffect(() => {
    setErrMsg('');
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...userData, email }));
      setEmail('');
      setPassword('');
      navigate(navigate_on_success);
    } catch (err) {
      if (!err.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
    }
  };

  const handleEmailInput = (e) => setEmail(e.target.value);
  const handlePasswordInput = (e) => setPassword(e.target.value);

  const content = isLoading ? <Spin /> : (
    <Card
      title="Portal - Mapper"
      bordered={false}
      style={{ width: 300 }}
      loading={isLoading}
      actions={[
        <Button
          onClick={() => navigate('/')}
          shape="round"
          size="large"
          block
          loading={isLoading}
        >
          Back
        </Button>,
        <Button
          onClick={handleSubmit}
          shape="round"
          size="large"
          block
          loading={isLoading}
        >
          Authenticate
        </Button>,
      ]}
    >
      <Space direction="vertical">
        <Input
          placeholder="E-Mail"
          onChange={handleEmailInput}
          ref={emailRef}
          value={email}
        />
        <Input.Password
          placeholder="Password"
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          onChange={handlePasswordInput}
          value={password}
        />
        <p
          ref={errRef}
          className={errMsg ? 'errmsg' : 'offscreen'}
          aria-live="assertive"
        >
          {errMsg}
        </p>
      </Space>
    </Card>
  );

  return content;
};

export default Login;

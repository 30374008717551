import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: { email: null, access_token: null, refresh_token: null },
  reducers: {
    setCredentials: (state, action) => {
      const { email, access, refresh } = action.payload;
      state.access_token = access;
      state.refresh_token = refresh;
      if (state.email === null) {
        state.email = email;
      }
    },
    logOut: (state, action) => {
      state.access_token = null;
      state.refresh_token = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentEmail = (state) => state.auth.email;
export const selectAccessToken = (state) => state.auth.access_token;
export const selectRefreshToken = (state) => state.auth.refresh_token;
